@import "../../../../config/theme/theme";
@import "../../../../assets/sass/functions/functions";

.protection-discovery {
  &__cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    height: 100%;
  }

  &__col {
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
    padding: 30% 9% 0% 9%;

    * {
      text-align: center;
    }

    &-title {
      line-height: 2.8125rem;
    }

    &-subtitle {
      font-size: 1.5rem;
      display: grid;
      place-items: center;
    }

    &:nth-child(1) {
      background-image: url("../../../../assets/img/backgrounds/product-test-protection-first-col-bg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }

    &:nth-child(2) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    &:nth-child(3) {
      background: #fafafa;
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }
  }

  &__prime-logo {
    width: 2.8125rem;
    height: 2.8125rem;
    vertical-align: middle;
  }

  &__sounds {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-sizing: border-box;
    border-radius: 1.5rem 1.5rem 0 0;
    overflow: hidden;

    &-standard,
    &-hd {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      padding: 3.5rem;
      row-gap: 1rem;
    }

    &-standard {
      background: get($theme, ("palette", "primary", "main"));
    }

    &-hd {
      background: get($theme, ("palette", "secondary", "main"));
    }

    &-title {
      border-bottom: 0.2rem solid #ffffff85;

      svg {
        padding-right: 0.5rem;
        width: 1.1rem;
        height: .9rem;        
      }
    }

    &-subtitle {
      margin-left: 1rem;
    }

    &-title,
    &-subtitle {
      color: white;
      font-style: italic;
    }

    &-col {
      & > * {
        &:first-child {
          margin-bottom: 1rem;
          padding-bottom: 0.5rem;
        }

        margin-bottom: 1rem;
        padding-bottom: 1rem;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;

      button {
        width: 1.75rem;
        height: 1.75rem;
        margin-left: 1rem;
        padding: 0;
      }
    }
  }
}
