.progress {
  &[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;

    &::-webkit-progress-bar {
      background-color: #c4c4c4;
    }

    &::-webkit-progress-value {
      background-color: #0164ff;
    }

    &::-moz-progress-bar {
      background-color: #0164ff;
    }
  }
}
