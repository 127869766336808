@import "../../../../assets/sass/functions/functions";

.questions {
  .hearing-test-view-wrapper__content {
    width: 80%;
  }

  &__part {
    $space: 3rem;

    &:first-child {
      padding-bottom: $space;
    }

    &:last-child {
      padding-top: $space;
    }

    &:not(:last-child) {
      border-bottom: 2px dashed #0164FF;

      &:not(:first-child) {
        padding: $space 0;
      }
    }
  }

  &__text {
    font-size: scaleFromIpad(25);
    margin-bottom: 3rem;
    font-weight: 700;
  }

  &__checkboxes {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    .checkbox {
      margin: unset;
      text-transform: unset;
    }
  }
}
