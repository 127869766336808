@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

.audio-button {
  $size: scaleFromIpad(78);
  border: unset;
  background: unset;
  box-shadow: unset;
  cursor: pointer;
  margin: unset;
  border-radius: 22%;
  width: $size;
  height: $size;
  background: get($theme, ("palette", "primary", "main"));
  display: grid;
  place-items: center;

  svg {
    width: 100%;
    height: 100%;
  }

  &:not(&--inverted-color) {
    background: white;
    svg {
      & > path:not([fill="white"]) {
        fill: transparent;
      }

      & > path[fill="white"] {
        fill: get($theme, ("palette", "primary", "main"));
      }
    }
  }
}
