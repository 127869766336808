.view-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 10vh 90vh;
  grid-template-areas: "header" "content";

  &--full-height {
    grid-template-rows: 100vh;
    grid-template-areas: "content";
  }

  .header {
    grid-area: header;
  }

  &__component {
    grid-area: content;
  }
}
