@import "../../../../assets/sass/functions/functions";

.hearing-test-view-wrapper {
  display: grid;
  grid-template-rows: 22% 58% 20%; // equals to 100%
  grid-template-areas: "title"
                       "content"
                       "buttons";
  row-gap: 15px;

  &__title {
    grid-area: title;
    justify-self: center;
    align-self: flex-end;
    margin: 0 8%;
    font-size: scaleFromIpad(30);
    text-align: center;
  }

  &__content {    
    grid-area: content;
    justify-self: center;
    align-self: center;
  }

  &__action-buttons {
    grid-area: buttons;
    justify-self: center;
    align-self: start;

    button {
      min-width: 17rem;
      font-size: scaleFromIpad(20);
      line-height: (45px);
    }
  }

  &__back-btn {
    background: #b3b3b3;
  }

  &__next-btn {
    background: #005ae7;
  }

  &__back-btn~&__next-btn {
    margin-left: 2rem !important;
  }
}
