@import "../../../../assets/sass/mixins/mixins";
@import "../../../../assets/sass/functions/functions";

.hearing-test-measurement {
  &__title {    
    margin-bottom: unset;
    text-transform: uppercase;        
  }

  .hearing-test-view-wrapper__title {
    margin: 0 15%;
    font-weight: 400;
    font-size: scaleFromIpad(30);
  }

  .hearing-test-measurement__title {
    font-size: scaleFromIpad(20);
    font-weight: 700;
  }

  .hearing-test-view-wrapper__content {    
    display: flex;
    flex-direction: column;
    align-items: center;

    .player {
      margin-top: 3rem;
    }

    .active-player {
      @include d-flex-fully-centered();
    }
  }
}
