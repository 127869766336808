.experience-tour-virtual-video {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../../../assets/img/backgrounds/experience-tour_ear-vr.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 65%;

  h2 {
    font-size: 2.5rem;
    font-weight: 900;
    color: #FFFFFF;    
    min-width: 40%;
  }

  ul {
    padding-left: 1rem;
    min-width: 40%;
    box-sizing: border-box;
    
    li, p {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 1.7rem;
    }
  } 
}
