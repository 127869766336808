@import "../../../../assets/sass/mixins/mixins";
@import "../../../../assets/sass/functions/functions";

.setup-instructions {
  display: grid;
  grid-template-rows: 22% 58% 20%; // equals to 100%
  grid-template-areas: "title"
                       "content"
                       "buttons";
  row-gap: 15px;

  &__title {
    grid-area: title;
    justify-self: center;
    align-self: flex-end;
    margin: 0 8%;
    font-size: scaleFromIpad(30);
    text-align: center;
  }

  &__content {
    grid-area: content;
    justify-self: center;
    align-self: center;
    display: flex;
    width: 70%;
    justify-content: space-evenly;
  }

  &__action-buttons {
    grid-area: buttons;
    justify-self: center;
    align-self: start;

    button {
      min-width: 17rem;
      font-size: scaleFromIpad(20)
    }
  }

  &__back-btn~&__next-btn {
    margin-left: 2rem !important;    
  }

  &__instruction {
    @include d-flex-fully-centered();
    flex-direction: column;
  }

  &__logo {
    margin-bottom: 1.25rem;
  }
}
