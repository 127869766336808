@import "../../../../config/theme/theme";
@import "../../../../assets/sass/functions/functions";

.product-test-sound-calibration {
  background-image: url("../../../../assets/img/backgrounds/product_test_sound_calibration_bg.jpg");
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-rows: 25% 5% 25% 20% 25%;
  grid-template-areas: "title"
                       "subtitle"
                       "audio-container"
                       "buttons"
                       "submit";

  &__title {
    grid-area: title;
    justify-self: center;
    align-self: end;
    font-size: scaleFromIpad(30);
  }

  &__subtitle {
    grid-area: subtitle;
    justify-self: center;
    align-self: start;
    font-size: scaleFromIpad(25);
  }

  &__audio-container {
    grid-area: audio-container;
    margin-inline: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    progress {
      margin-left: 15px;
    }
  }

  &__audio-button {
    background: transparent !important;

    &[data-is-playing="false"] {
      svg path {
        fill: #c4c4c4;
      }
    }
  }

  &__volume-btn-container {
    margin-inline: 8%;
    grid-area: buttons;

    .product-test-sound-calibration__volume-btn {
      position: relative;
      display: inline-flex;
      justify-content: center;
      padding: 1rem 3.5rem;
      transition: all 300ms ease-in-out;
      font-size: scaleFromIpad(20);
      font-weight: 500;      

      &::after {
        font-weight: 400;
        line-height: scaleFromIpad(45);
      }

      &:not(:last-child) {
        margin-right: 50px;
      }

      &[data-selected="false"] {
        background-color: #c4c4c4;
      }

      &:hover {
        background-color: get($theme, ("palette", "primary", "main"));
      }

      &[data-selected="true"]::before {
        content: "";
        position: absolute;
        top: -45px;
        background-image: url("../../../../assets/img/svg/sound.svg");
        width: 30px;
        background-repeat: no-repeat;
        background-size: 30px 30px;
        height: 30px;
      }

      &::after {
        content: attr(data-subtext);
        width: max-content;
        margin-inline: auto;
        position: absolute;
        top: 110%;
        color: #424242;
      }
    }
  }

  &__submit-btn {
    grid-area: submit;                
    font-size: scaleFromIpad(25);    
    font-weight: 500;
    line-height: scaleFromIpad(45);    
    text-align: center;
    padding: scaleFromIpad(7) scaleFromIpad(27);

  }

  &__volume-btn-container,
  &__submit-btn {
    justify-self: center;
    align-self: center;
  }
}
