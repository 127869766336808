@import "src/config/theme/theme";
@import "src/assets/sass/functions/functions";
@import "src/assets/sass/mixins/mixins";

.schedule-calendar {
  $primary-color: get($theme, ("palette", "primary", "main"));  

  &__form {
    padding: scaleFromIpad(73) scaleFromIpad(50);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    h4 {
      font-size: scaleFromIpad(30);
      line-height: scaleFromIpad(45);
    }


    .input-wrapper__input {
      padding: 0.262rem 1rem;
    }

    .schedule-calendar__title {
      font-size: scaleFromIpad(20);
      line-height: scaleFromIpad(24);      
      font-weight: 500;
      width: 15rem;
    }
    
    .textarea-wrapper__label {
      font-size: scaleFromIpad(20);
      line-height: scaleFromIpad(45);
    }

    .textarea-wrapper__textarea {
      font-size: scaleFromIpad(15);
    }
    
    & > * {
      margin-bottom: unset;

      &:not(button) {
        width: 100%;
      }
    }


    button[type="submit"] {      
      font-size: scaleFromIpad(20);      
    }

    .textarea-wrapper__label {
      color: #2d2d2d;
    }

    .textarea-wrapper__textarea {
      &::placeholder {
        color: lighten(#2d2d2d, 50);
      }
    }
  }

  &__calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: scaleFromIpad(59) scaleFromIpad(50);
  }

  .react-calendar {
    background: $primary-color;    
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 10vh 3vw 3vw 3vw;
    border-radius: 5vw;

    &__navigation {
      display: flex;      

      &__arrow {
        width: scaleFromIpad(40);
        height: scaleFromIpad(40);
        border-radius: 50%;
        border: unset;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 3px 0;
        background: transparent;
        color: #FFFFFF;
        cursor: pointer;

        &[disabled] {
           display: none !important;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }

      &__prev2-button,
      &__next2-button {
        display: none;
      }

      &__label {
        background: transparent;
        border: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 800;
        font-size: scaleFromIpad(30);
        line-height: scaleFromIpad(41);
        text-transform: capitalize;
        color: #FFFFFF;
        pointer-events: none;
        padding-bottom: scaleFromIpad(83);
      }
    }

    &__viewContainer {
      flex: 1;
      height: 80%;
    }

    &__month-view {
      height: 100%;

      &__days {        
        height: 90%;
        display: flex;
        flex-wrap: wrap;
        padding-top: scaleFromIpad(29);
        box-sizing: border-box;
      }

      & > div {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch !important;
      }
    }

    &__month-view__days__day--neighboringMonth {
      pointer-events: none;
      opacity: 0.5;
    }

    &__month-view__weekdays {
      padding-bottom: scaleFromIpad(16.55);
      border-bottom: 1px solid white;
      box-sizing: border-box;      

      &__weekday {
        text-align: center;

        abbr {
          font-size: scaleFromIpad(12);
          line-height: scaleFromIpad(14);
          color: #FFFFFF;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }

    &__tile {
      background: transparent;
      padding: 0;
      border: unset;
      position: relative;
      cursor: pointer;

      &:not(&--active):hover {
        &::before {
          background: rgba(255, 255, 255, 0.1);
        }
      }

      &::before {
        content: "";
        height: scaleFromIpad(40);
        width: scaleFromIpad(40);
        border-radius: 50%;
      }

      abbr {
        color: #FFFFFF;
        font-size: scaleFromIpad(14);
        line-height: scaleFromIpad(16);
      }

      &::before,
      abbr {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &--active {
        &::before {
          background: white;
        }

        abbr {
          color: $primary-color;
        }
      }
    }
  }

  .input-wrapper__input[name="date"] {
    pointer-events: none;
  }

  select.input-wrapper__input {
    appearance: none; /* Remove default arrow */
    background: #fff;
    box-sizing: content-box;
  }
}
