.introduction {

  .hearing-test-view-wrapper {
    &__content {
      width: 80%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  &__form {
    width: 100%;
    height: 400px;
    background-image: url("../../../../assets/img/backgrounds/woman-hearing.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    & > *:last-child {
      margin-bottom: 15px;
    }
  }

  &__good-btn.ant-radio-button-wrapper-checked {
    background-color: lightseagreen !important;
    border-color: lightseagreen !important;
  }

  &__bad-btn.ant-radio-button-wrapper-checked {
    background-color: lightcoral !important;
    border-color: lightcoral !important;

    &::before {
      background-color: lightcoral !important;
    }
  }

  &__idk-btn.ant-radio-button-wrapper-checked {
    background-color: lightgray !important;
    border-color: lightgray !important;

    &::before {
      background-color: lightgray !important;
    }
  }

  &__next-btn {
    margin-top: 50px;
  }
}
