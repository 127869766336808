@import "../../../../assets/sass/functions/functions";

.product-test-device-choices {
  display: grid;
  grid-template-columns: repeat(5, 1fr);  
  align-items: center;
  justify-items: center;
  grid-gap: scaleFromIpad(25);
  padding-inline: scaleFromIpad(30);

  &__selection-card {
    box-sizing: border-box;
    width: 100%;
    height: 55%;    

    .padding-wrapper {
      height: 100%;
      padding: 15% 0;
      align-items: center;
    }

    &:not(&--inverted-color) {
      background-color: #efefef;
    }

    img {            
      width: 100%;
      height: 50%;
      object-fit: cover;
      min-height: 22rem;
      max-width: scaleFromIpad(242);
      
    }

    h3 {
      font-size: 2.25rem;
      margin-bottom: unset;
      height: 35%;
      width: 90%;
    }

    button {
      height: 15%;
      background: white;
      color: #434343;      
      width: 90%;

      &:hover {
        color: white !important;
      }
    }
  }
}
