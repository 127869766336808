@import "../../../../assets/sass/functions/functions";

.hearing-test-thanks {
  background-image: url("../../../../assets/img/backgrounds/hearing_test_thanks.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__content {
    position: absolute;
    top: -27px;

    $width: 40%;
    $margin-right: 10%;
    margin-left: calc(100% - #{$width} - #{$margin-right} + 3%);
    width: $width;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    * {
      margin: unset !important;

      &:not(:last-child) {
        margin-bottom: 2rem !important;
      }

      &.hearing-test-thanks__title {
        margin-bottom: 2.5rem !important;;
      }
    }

    button {
      font-size: scaleFromIpad(20);
    }
  }

  &__check-icon {
    $size: scaleFromIpad(100);
    width: $size;
    height: $size;
  }

  &__title {    
    line-height: 5rem;
    color: white;
    font-size: 3.5rem;
  }

  &__text {
    font-size: scaleFromIpad(20);
    font-weight: bold;
    color: white;
    
  }
}
