@import "../../../../config/theme/theme";
@import "../../../../assets/sass/functions/functions";


.product-test-headset-choices {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;

  .product-test-headset-choices__col {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;    
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5rem;
    box-sizing: border-box;

    .product-test-headset-choices__text {
      padding-top: 3rem;
      text-align: center;
      color: #FFFFFF;
      font-weight: 500;
      line-height: 2rem;
      font-size: scaleFromIpad(25);
      line-height: scaleFromIpad(29);
    }

    &:nth-child(1) {
      background-image: url("../../../../assets/img/backgrounds/product_test_headset_choices_1.jpg");
    }

    &:nth-child(2) {
      background-image: url("../../../../assets/img/backgrounds/product_test_headset_choices_2.jpg");
    }

    &:nth-child(3) {
      background-image: url("../../../../assets/img/backgrounds/product_test_headset_choices_3.jpg");
    }
  }
}
