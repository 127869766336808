.hearing-loss-simulator {
  background-image: url("../../../../assets/img/backgrounds/experience-tour_hearing-loss-simulator.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__box {
    padding: 22rem 0;

    .hearing-loss-simulator-box {      
      &__title, &__text {
        text-align: center;
      }

      &__text {
        font-size: 2vh;
      }

      &__subtitle, &__text {
        font-weight: 500;
      }
    }
  }
}
