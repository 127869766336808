@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

.typography {
  margin-top: unset;

  &--as-title,
  &--as-subtitle {
    font-family: 'Anton', serif;
  }

  &--as-title {
    font-size: 2.8125rem;
    font-weight: 500;
  }

  &--as-subtitle {
    font-weight: 500;
    font-size: 1.55rem;
    line-height: 2rem;
  }

  &--as-body {
    font-size: 1.25rem;
  }

  &--as-caption {
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  &--as-small {
    font-size: 0.80rem;
  }

  &--primary-color {
    color: get($theme, ("palette", "primary", "main"));
  }

  &--secondary-color {
    color: get($theme, ("palette", "secondary", "main"));
  }

  &--error-color {
    color: get($theme, ("palette", "error", "main"));
  }

  &--text-color {
    color: get($theme, ("palette", "dark", "main"));
  }

  &[data-align="left"] { text-align: left; }
  &[data-align="right"] { text-align: right; }
  &[data-align="center"] { text-align: center; }
  &[data-align="justify"] { text-align: justify; }
  &[data-align="initial"] { text-align: initial; }
  &[data-align="inherit"] { text-align: inherit; }
}
