@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

.button {
  $primary: get($theme, ("palette", "primary", "main"));
  background-color: $primary;
  border-radius: 10px;
  border: unset;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: darken($primary, 5%) !important;
  }

  &--inverted-color {
    background-color: white;
    color: get($theme, ("palette", "primary", "main"));

    &:hover {
      background-color: darken(white, 3%) !important;
    }
  }

  &--small {
    padding: 0.6rem 1.5rem;
    border-radius: 0.75rem;
    font-size: 0.85rem;
  }

  &--medium {
    padding: 0.85rem 2.3rem;
    border-radius: 0.75rem;
    font-size: 1.2rem;
  } 

  &--large {
    padding: 1.3rem 3.5rem;
    font-size: 1.5rem;
    border-radius:  0.75rem;
  }

  &--circled {
    border-radius: 50%;
    padding: 2rem 2rem;
  }

  &[disabled] {
    background: #b3b3b3; // Old browsers
    background: linear-gradient(to right, #b3b3b3, #B0B0B0);
    cursor: not-allowed;
  }
}
