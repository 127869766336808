@import "../../../../assets/sass/functions/functions";

.hearing-test-welcome {
  $width: 60%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;  

  &__title {
    font-size: scaleFromIpad(40);
  }

  &::after {
    content: "";
    display: block;
    width: calc(100% - #{$width});
    height: 100%;
    background-image: url("../../../../assets/img/backgrounds/hearing_test_welcome.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    z-index: -1;
  }

  & > * {
    margin: 0 scaleFromIpad(130);
  }

  & > *:not(:last-child) {
    margin-bottom: 4rem;
  }

  &__subtitle {    
    font-size: scaleFromIpad(20);
    line-height: scaleFromIpad(50);
    
  }

  &__btn {
    padding: 1.8rem 3.5rem;
    font-size: scaleFromIpad(20);
    transition: all 300ms ease-in-out;

    &:hover {
      transform: scale(1.03);
    }
  }
}
