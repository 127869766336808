@import "../../../../config/theme/theme";
@import "../../../../assets/sass/functions/functions";

.protection-discovery-details {
  display: grid;
  grid-template-columns: 55% 1fr;
  overflow-y: hidden;

  &__bg-img {
    width: 100%;
    object-fit: cover;
  }

  &__content {
    padding: 3vh 0;
    box-sizing: border-box;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 90vh;
    width: 100%;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 15px;
      background-color: get($theme, ("palette", "primary", "main"));
      position: absolute;
      bottom: 0;
    }
  }

  &__title {
    margin: unset;
    font-size: 4vh;
  }

  &__text {
    margin: unset;
    text-align: center;
    font-size: 2.2vh;
    padding: 0 4.5vw;
    box-sizing: border-box;
    font-weight: 500;
  }

  &__subtext {
    margin: unset;
    font-size: 2.2vh;
    padding: 0 4.5vw;
    box-sizing: border-box;
    font-weight: 500;
  }

  &__price {
    margin: unset;
    font-family: Roboto, serif;
    font-size: 4.5vh;
    font-style: italic;
    font-weight: 900;
    line-height: 62px;
    letter-spacing: 0em;
    text-align: center;

  }

  &__audio-panel {
    width: 45vw;
    height: 50%;
    justify-content: space-evenly;
  }
}
