@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

.splashscreen {
  width: 100%;
  height: 100%;
  background: get($theme, ("palette", "background", "gradient"));
  display: flex;
  justify-content: center;
  align-items: center;

  &__logo {
    margin: 0 30px;
  }
}
