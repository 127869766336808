@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";
@import "../../../assets/sass/mixins/mixins";

.checkbox {
  $primary-color: get($theme, ("palette", "primary", "main"));

  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: scaleFromIpad(20);
  font-weight: 500;
  color: get($theme, ("palette", "dark", "main"));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin: unset;
    border: none;
  }

  /* Styles of checkmark */
  &__checkmark {    
    $size: scaleFromIpad(41);
    width: $size;
    height: $size;
    border: scaleFromIpad(7) solid $primary-color;
    border-radius: scaleFromIpad(8);
    margin-right: 1.5rem;
    box-sizing: border-box;
    transition: border-color 300ms ease-in-out;    

    &::after {
        content: "";
        background-image: url("../../../assets/img/svg/checkmark.svg");
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;        
        display: none;
        background-size: 90%;
        background-position: center;
        transition: all 300ms ease-in-out;
    }
  }

  /* On mouse-over, darken the border */
  &:hover {
    .checkbox__input ~ .checkbox__checkmark {
      &, &::after {
        border-color: darken($primary-color, 5%);
      }
    }
  }

  /* Show the checkmark when checked */
  &__input:checked ~ &__checkmark:after {
    display: block;
  }
}
