@import "../../../../../assets/sass/functions/functions";

.hearing-loss-simulator-box {
  display: flex;
  flex-direction: column;
  grid-row-gap: 9vh;
  align-items: center;

  &__rest-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: scaleFromIpad(30);
    line-height: scaleFromIpad(45);
  }

  &__subtitle {
    font-weight: 700;
    font-size: scaleFromIpad(35);
    line-height: scaleFromIpad(40);
  }

  &__text {
    font-weight: 500;
    font-size: scaleFromIpad(20);
    line-height: scaleFromIpad(40);
  }

  &__title, &__subtitle, &__text {
    color: #FFFFFF;
    margin: unset;
  }
}
