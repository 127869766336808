@import "src/config/theme/theme";
@import "src/assets/sass/functions/functions";

.discovery-col {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 25px 30px;

  &:nth-child(odd) {
    background: rgb(248,248,248);
  }

  * {
    margin-bottom: unset;
  }

  &__title {
    font-size: 2.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__prime-logo {
    margin-right: 5px;
  }

  &__review {
    width: 100%;
    display: flex;
    flex-direction: column;

    $inter-space: 12px;

    &-quality,
    &-ideal-for {
      width: 100%;
      justify-content: space-between;

      &[data-text]::before {
        content: attr(data-text);
        padding-inline-end: 25px;
        font-size: 1.8vh;
        font-weight: 300;
        font-style: italic;
      }
    }

    &-quality {
      display: flex;
      align-items: center;
      padding-bottom: $inter-space;
      font-size: 1.8vh;
      font-weight: 300;
      font-style: italic;

      svg {
        width: auto;
        height: 2vh;

        &:not(:last-child) {
          margin-right: 1vw;
        }
      }

      border-bottom: 1px solid rgba(1, 100, 255, 0.76);
    }

    &-ideal-for {
      display: flex;
      align-items: center;
      padding-top: $inter-space;

      svg {
        width: auto;
        height: 20px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &[data-selected="true"] path {
          fill: get($theme, ("palette", "primary", "main"));
        }
      }
    }
  }

  &__rating-star {
    &--selected {
      path {
        fill: get($theme, ("palette", "primary", "main"));
      }
    }
  }

  & > .audio-button {
    width: 6vh;
    height: 6vh;
  }

  &__subtext {
    font-size: 2vh;
  }

  &__price {
    font-family: 'Roboto', serif;
    font-size: 3vh;
    font-style: italic;
    font-weight: 600;
  }
}
