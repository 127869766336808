@import "../../../../assets/sass/mixins/mixins";
@import "../../../../assets/sass/functions/functions";

.setup-instructions {
  .hearing-test-view-wrapper__content {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding-inline: 5vw;
    box-sizing: border-box;
  }

  &__logo {
    width: scaleFromIpad(131);
    height: scaleFromIpad(131);
    margin-bottom: 2.5rem !important;
  }

  &__instruction {
    @include d-flex-fully-centered();
    flex-direction: column;

    p {
      font-weight: 500;
      font-size: scaleFromIpad(20);
    }
  }
}
