@import "src/config/theme/theme";
@import "src/assets/sass/functions/functions";

.discovery-panel {
  width: 100%;
  height: 55%;
  position: absolute;
  bottom: -55%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: get($theme, ("palette", "primary", "main"));
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-sizing: border-box;
  padding: 15px 30px 10px 30px;
  transition: all 1s;

  &--opened {
    bottom: 0;
  }

  &__close-btn {
    all: initial;
    height: 5vh;
    width: 5vh;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: grid;
    place-items: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    color: #FFFFFF;
    font-size: 1.8vh;
    font-style: italic;

    svg {
      padding-right: 5px;
    }
  }

  &__btn-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 15px;

    .audio-button {
      height: 3.5vh;
      width: 3.5vh;
      position: relative;

      svg {
        position: absolute;
        height: 2.9vh;
        width: auto;

        // Pos correction
        margin-bottom: 1px;
      }

      &[data-is-playing="true"] svg {
        border-color: #FFFFFF;
        border-top-right-radius: 4px;
      }

      &::after {
        content: attr(data-text);
        color: #FFFFFF;
        padding-top: 5px;
        font-size: 1.5vh;
        position: absolute;
        bottom: -100%;
        transform: translateY(-30%);
        width: max-content;
      }
    }
  }
}
