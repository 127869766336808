@import "../../../../assets/sass/functions/functions";
@import "../../../../config/theme/theme";

.player {
  button {
    &, &:hover, &:focus {
      all: unset !important;
      cursor: pointer !important;
    }

    &:not(:last-child) {
      margin-right: 50px !important;
    }
  }

  .active-player {

    button:nth-child(2) svg {
      $shadow-color: #1662ad;
      $shadow-spread: 30px;

      border-radius: 12px;
      box-shadow: 0 0 0 0 rgba($shadow-color, .5);
      animation: pulse 1.6s infinite;
      -webkit-animation: pulse 1.6s infinite;
      -moz-animation: pulse 1.6s infinite;

      &:hover {
        -webkit-animation: none;
      }

      @-webkit-keyframes pulse {
        70% {
          box-shadow: 0 0 0 $shadow-spread rgba($shadow-color, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($shadow-color, 0);
        }
      }

      @-moz-keyframes pulse {
        70% {
          box-shadow: 0 0 0 $shadow-spread rgba($shadow-color, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($shadow-color, 0);
        }
      }

      @keyframes pulse {
        70% {
          box-shadow: 0 0 0 $shadow-spread rgba($shadow-color, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($shadow-color, 0);
        }
      }
    }
  }
}
