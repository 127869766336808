@import "../../../../assets/sass/functions/functions";

.product-test-choices {
  &__column-selection {
    justify-content: space-between;
    padding: 0;
    padding-top: scaleFromIpad(271);
    padding-bottom: scaleFromIpad(257);

    button {
      color: #424242;
    }
  }

  .column-selection__title {    
    font-size: scaleFromIpad(30);
  }

  .column-selection__subtitle {
    font-weight: 500;
    font-size: scaleFromIpad(20);    
  }

  button {
    font-weight: 700;
    font-size: scaleFromIpad(20);    
  }

  &__simulator-column-selection {
    & > .column-selection__title {
      padding-inline: 10%;
    }
  }
}
