@import "../../../../config/theme/theme";
@import "../../../../assets/sass/functions/functions";

$fs_title: scaleFromIpad(30);
$fs_text: scaleFromIpad(20);
$fs_button: scaleFromIpad(20);
$width: 35vw;
$height: 55vh;
$padding: 20% 20% 10% 20%;

.selection-card {  
  height: $height;
  width: $width;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
  text-align: center;
  box-sizing: border-box;  

  .padding-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: $padding;
  }
  & > * {
    margin: unset;
  }

  &__title {
    font-family: Anton, serif;
    font-size: $fs_title;
  }

  &__subtitle {    
    font-size: $fs_text;    
  }
  
  button {
    transition: transform 300ms ease-in-out;
    font-size: $fs_button;
    border-radius: scaleFromIpad(10);

    &:hover {
      transform: scale(1.03);
    }
  }
}
