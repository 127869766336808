@import "../../../../assets/sass/functions/functions";

.schedule-sign-in {
  width: 100%;
  height: calc(65vh - 70px); // TODO: globalize 70px (header height)
  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/img/backgrounds/hearing_test_signin.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  &__subtitle {
    padding-top: 7vw;
    font-size: scaleFromIpad(30);
    line-height: scaleFromIpad(30);
  }

  &__form {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-left: 10vw;

    .input-wrapper > .input-wrapper__input {
      height: scaleFromIpad(63);
      font-size: scaleFromIpad(20);
    }
    
    .input-wrapper > .input-wrapper__input,
    .input-wrapper > .input-wrapper__input::placeholder {
      text-align: center;
    }
  }

  &__submit-btn {
    margin-top: scaleFromIpad(40);;
    align-self: flex-start;
    font-size: scaleFromIpad(20);
  }
}
