@import "../../../../assets/sass/functions/functions";
@import "../../../../config/theme/palette.scss";

.experience-tour-questions {
  padding: 15vh 5vw 7vh 5vw;
  background-image: url("../../../../assets/img/backgrounds/experience-tour_questions.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;

  &__title {
    width: fit-content;
    width: -moz-fit-content;
    margin-bottom: 2rem;
    position: relative;
    font-size: scaleFromIpad(60);

    &::after {
      content: "";
      display: block;
      height: scaleFromIpad(1);
      width: 85%;
      background-color: grey;
    }
  }

  &__hidden-logout-btn {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20px;
    transform: translateX(100%);
  }

  &__subtitle {
    width: 42%;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.6rem;
  }

  & > .exp-tour-questions-accordion {
    .accordion__key {      
      padding-inline: 0;
      font-size: scaleFromIpad(20);
      color: get($palette, ("dark", "main"));
    }

    .accordion__panel {
      padding: 0;
      font-size: scaleFromIpad(20);
      line-height: 1.6rem;
      color: get($palette, ("dark", "main"));
    }

    .accordion__key,
    .accordion__panel {
      background: transparent;
      border-bottom-color: transparent;
    }
  }
}
