@import "../../../assets/sass/mixins/mixins";
@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

.accordion {
  width: 100%;
  $main-primary-color: get($theme, ("palette", "primary", "main"));
  $main-secondary-color: get($theme, ("palette", "secondary", "main"));
  $border-size: 4px;

  &__key {
    color: #1E1E1E;
    cursor: pointer;
    padding: 14px;
    width: 100%;
    border: none;
    border-bottom: $border-size solid #FFFFFF;
    text-align: left;
    outline: none;
    font-size: 0.95em;
    font-weight: 700;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    position: relative;
    background-color: white;

    &::before {
      content: "+";
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $main-primary-color;
      color: white;
      border-radius: 4px;
      padding-bottom: 1px;
      font-weight: bolder;
      margin-right: 10px;
    }

    &::after {
      content: "";
      border-bottom: 1px solid black;
      position: absolute;
      bottom: 0;
      width: calc(100% - 15px * 2);
    }

    &:hover {
      background: #f3f3f3;
    }

    &--active {
      &::before {
        content: "-";
      }
    }
  }

  &__panel {
    display: none;
    padding: 0 18px $border-size 18px;
    overflow: hidden;
    background-color: white;
    line-height: 1.5em;
    font-size: 0.92em;
    text-align: justify;

    &--active {
      display: block;
    }
  }
}
