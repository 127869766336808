@import "../../../../assets/sass/functions/functions";

.schedule-thanks {
  background-image:
          linear-gradient(294.76deg, #8e8e8e 1%, rgba(91, 91, 91, 0.19) 60%),
          url("../../../../assets/img/backgrounds/schedule_thanks.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__content {
    position: absolute;
    top: -27px;

    $width: 40%;
    $margin-right: 10%;
    margin-left: calc(100% - #{$width} - #{$margin-right});
    width: $width;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    * {
      margin: unset !important;

      &:not(:last-child) {
        margin-bottom: 2rem !important;
      }

      &.schedule-thanks__title {
        margin-bottom: 2.5rem !important;;
      }
    }

    button {
      font-size: scaleFromIpad(20);
    }
  }

  &__check-icon {
    $size: scaleFromIpad(100);
    width: $size;
    height: $size;
  }

  &__title {
    font-size: scaleFromIpad(45);
    line-height: scaleFromIpad(60);
    color: white;
  }

  &__text {
    font-size: scaleFromIpad(20);
    font-weight: bold;
    color: white;
  }
}
