@import "../../../../config/theme/theme";
@import "../../../../assets/sass/functions/functions";

.product-test-discovery {
  display: flex;
  flex-flow: column;

  .product-test-discovery__bg {
    object-fit: cover;
    object-position: bottom;
    height: 38vh;
  }

  &::after {
    content: "";
    height: 20px;
    width: 100%;
    background-color: get($theme, ("palette", "primary", "main"));
  }

  .cols[data-product-type="lyr"],
  .cols[data-product-type="hea"] {
    .discovery-col {
      width: 33%;
      justify-self: center;
      background: transparent;
    }
  }
}
