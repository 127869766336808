@use "sass:map";
@import "/src/config/theme/theme.scss";

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    &__form {
        padding: 3em;
        border-radius: 8px;
        border: 1px solid #dadce0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-row-gap: 2.5rem;

        &__item {
            display: flex;
            flex-direction: column;
        }
    }

    &__logo {
        height: 10vh;
        margin-bottom: 0.5em;
    }
}
