:root {
  --app-primary: #0164FF;
  --app-secondary: #003B9D;
  --app-info: #666;
  --app-error: #f44336;
  --app-dark: #424242;
  --app-gradient: linear-gradient(to right, #0164FF, #003B9D);
  --app-inverted-gradient: linear-gradient(to left, #0164FF, #003B9D);  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; /** no view w/ scroll */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.adm-picker-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

.adm-picker-header-button {
  font-weight: bold;
  color: var(--app-primary)
}

.adm-picker-header-title {
  flex: none;
}

@media (orientation: portrait) {
  .view-wrapper:not(.view-wrapper--full-height) {
    grid-template-rows: 93vh 7vh;
    grid-template-areas: "content" 
                         "header";

    .header {
      width: inherit;
      height: inherit;
      background: linear-gradient(to right, #0164FF, #003B9D);
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 15px;
      grid-template-areas: ". . ." "back-btn title home-btn" ". . . ";
      grid-template-rows: 1fr 1fr 1fr;
      place-items: center;

      .header__title {
        color: #ffffff;
        font-family: Anton, serif;
        font-style: normal;
        font-weight: 400;
        margin: unset;
        grid-area: title;
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }

  .dashboard {
    all: revert;
    width: inherit;
    height: inherit;
    display: grid;
    place-items: center;  
  }
  
  .dashboard__btn[data-key="experience-tour"] {
    all: revert;
    background-image: url("assets/img/backgrounds/1080x1920/dashboard_experience_tour.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
    width: inherit;
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    justify-content: center;
    align-items: end;
    cursor: pointer;
    
    .dashboard__btn-text {
      margin-bottom: revert;
      border-radius: 4.5rem;
      grid-row: 6 / span 1;
      background: rgba(255, 255, 255, .2);
      font-size: 4.5rem;
      line-height: 4.5rem;
      padding-inline: 30px;
      padding-block: 20px;
      border: 2px solid white;
    }
  }

  .experience-tour-video-choices {
    background-image: url("assets/img/backgrounds/1080x1920/experience_tour_video_choices.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .experience-tour-video-choices__col {
      &:nth-child(1), &:nth-child(2) {
        background-image: unset;
      }

      .audio-button {
        top: 84%;
      }

      .experience-tour-video-choices__text {
        top: calc(84% + 6rem);        
      }

    }
  }

  .experience-tour-virtual-video {
    all: revert;
    background-image: url("assets/img/backgrounds/1080x1920/experience_tour_ear-vr.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr 60vw 1fr;
    grid-template-rows: repeat(24, 1fr);

    h2 {
      grid-column: 2 / 3;
      grid-row: 15 / span 3;
      font-size: 3rem;
    }

    ul {
      grid-column: 2 / 3;
      grid-row: 18 / span 3;
      padding-left: 1rem;

      li {
        p {
          font-size: 2rem;
        }        
      }
    }
  }
}